#root,
html,
body {
  margin: 0;
  overflow: hidden;
  font-family: sans-serif, 'Roboto', Arial;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  text-align: center;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-container {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: black;
  color: white;
  align-items: center;
  padding: 20px;
  height: 35px;
}

.footer {
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.author {
  font-size: 30px;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.menu div {
  position: relative;
}

.menu a.route-link {
  margin: 0 20px;
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.menu .route-link.active {
  text-decoration: underline;
}

.menu .lang-select:focus {
  border: none;
}

.menu-burger {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 70px;
  background-color: black;
  width: 100%;
  z-index: 2;

}

.menu-burger a.route-link {
  display: block;
  padding: 20px;
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.page-container {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.page-container-gallery {
  height: auto;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 2px;
  overflow: hidden;
}

.home {
  position: relative;
  align-self: center;
  display: flex;
  max-width: 1000px;
  justify-content: space-around;
  margin: 0 auto;
  gap: 10px;
}

.projects {
  align-self: center;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}

.contacts {
  max-width: 1000px;
  align-self: center;
}

.row-container {
  max-width: 650px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}

.row-container a:link,
.row-container a:visited {
  color: black;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.row-container img {
  margin-left: 5px;
}

.interactive-container {
  position: relative;
}

.column {
  width: 40%;
}

.photo,
.video {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 300px;
  max-width: 500px;
  box-sizing: border-box;
}

.experiments-video {
  display: inline-block;
  max-width: 500px;
}

span.lang {
  display: inline-block;
  color: white;
  font-weight: 600;
  border: 1px solid white;
  padding: 5px;
  width: 23px;
}

.lang:hover {
  cursor: pointer;
}

.bi-heart-fill {
  background: -webkit-linear-gradient(180deg, #ffd700 50%, #0057b7 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0057b7;
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 5px;
}

.bi-heart {
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 5px;
}

.bi-telegram,
.bi-facebook {
  font-size: 35px;
  margin-right: 10px;
  color: black;
}

.bi-facebook:hover,
.bi-telegram:hover {
  color: #444;
}

.bi-instagram {
  font-size: 21px;
  color: #fff;
}

.bi-list {
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 10px;

}

.circle:hover {
  background: #444;
}

.logo {
  width: 30px;
}

.bold {
  font-weight: 600;
  text-align: left;
}

.text {
  text-align: left;
  font-size: 20px;
  display: block;
}

.stories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 600px;
  font-size: 20px;
  line-height: 30px;
  align-self: flex-start;
}

.stories a {
  text-decoration: none;
  color: black;
  font-weight: 500;

}

.stories a:hover {
  color: #444;
}

.story {
  align-self: start;
  max-width: 1000px;
  margin: 0 auto;
  height: fit-content;
  font-size: 18px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: pre-line;
  text-align: left;
  line-height: 25px;
}

/* ImageGallery */

.horizontal-gallery-container {
  height: 100%;
  width: fit-content;
  overflow-x: scroll;
  box-sizing: border-box;
}

.horizontal-gallery {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.color-group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.thumbnail-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.thumbnail.loaded {
  opacity: 1;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 80%;
  height: 80%;
  overflow: hidden;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;

}

.modal:focus {
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.modal-image.landscape {
  width: 100%;
  height: auto;
}

.modal-image.portrait {
  width: auto;
  height: 100%;
  text-align: center;
}

@media only screen and (max-width: 850px) {
  .page-container {
    max-width: 90%;
    ;
  }

  .text {
    font-size: 16px;
  }

  .author {
    font-size: 20px;
  }

  .row-container a:link,
  .row-container a:visited {
    color: black;
    font-size: 16px;
  }

  a.route-link {
    margin: 0 10px;
    font-size: 16px;
  }

  .photo {
    width: 300px;
  }

  .horizontal-gallery {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 550px) {
  .home {
    margin: 0 auto;
    flex-direction: column-reverse;
  }

  .video,
  .photo {
    position: relative;
    width: 100%;
  }

  .column {
    width: 100%;
  }
}